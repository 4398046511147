@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Thin-webfont.eot");
  src:
    url("./fonts/ProximaNova-Thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/ProximaNova-Thin-webfont.woff") format("woff"),
    url("./fonts/ProximaNova-Thin-webfont.ttf") format("truetype"),
    url("./fonts/ProximaNova-Thin-webfont.svg#ProximaNovaThThin") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Light-webfont.eot");
  src:
    url("./fonts/ProximaNova-Light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/ProximaNova-Light-webfont.woff") format("woff"),
    url("./fonts/ProximaNova-Light-webfont.ttf") format("truetype"),
    url("./fonts/ProximaNova-Light-webfont.svg#ProximaNovaLtLight")
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-janesymbols-webfont.eot");
  src:
    url("./fonts/proximanova-janesymbols-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/proximanova-janesymbols-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-janesymbols-webfont.woff") format("woff"),
    url("./fonts/proximanova-janesymbols-webfont.ttf") format("truetype"),
    url("./fonts/proximanova-janesymbols-webfont.svg#proxima_novajanesymbols")
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Sbold-webfont.eot");
  src:
    url("./fonts/ProximaNova-Sbold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/ProximaNova-Sbold-webfont.woff") format("woff"),
    url("./fonts/ProximaNova-Sbold-webfont.ttf") format("truetype"),
    url("./fonts/ProximaNova-Sbold-webfont.svg#ProximaNovaLtSemibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-bold-janesymbols-webfont.eot");
  src:
    url("./fonts/proximanova-bold-janesymbols-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/proximanova-bold-janesymbols-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-bold-janesymbols-webfont.woff") format("woff"),
    url("./fonts/proximanova-bold-janesymbols-webfont.ttf") format("truetype"),
    url("./fonts/proximanova-bold-janesymbols-webfont.svg#proxima_nova_bold_janesymbols")
      format("svg");
  font-weight: 700;
  font-style: normal;
}
