@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./proxima-nova.css";

* {
  font-family: "Proxima Nova";
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 1.42;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control:focus {
  outline: 0;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(139, 213, 216, 0.6);
}
